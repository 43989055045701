.stylish-toast {
  position: absolute;
  top: 80px;
  right: 20px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.6); 
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
  color: #333;
  min-width: 300px;
  min-height: 80px;
  .toast-header {
    background: linear-gradient(#cff3e1, #cff3e1);
    border-bottom: none;
    color: #343a40;
    font-weight: bold;
    .btn-close {
      color: #343a40;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
  .toast-body {
    padding: 16px;
    font-size: 0.95rem;
    color: #343a40;
  }
  // Media query for responsiveness
  @media (max-width: 576px) {
    right: 10px;
    top: 60px;
    width: 90%;
  }
  @media (min-width: 577px) {
    right: 20px;
    top: 80px;
  }
}


.title-cell {
  .edit-icon {
      opacity: 0; // Hidden initially
      transition: opacity 0.3s ease; // Smooth transition when icon appears
      position: absolute;
      top: 52%;
      left: 26%;
      // transform: translate(-10%, -10%);
  }

  &:hover {
      .edit-icon {
          opacity: 1; // Make icon visible on hover
      }
  }
}


/* Card container styles */
.card-container {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  align-items: center;
  justify-content: space-around; /* Spread content evenly */
  padding: 1rem;
  height: 140px; /* Original width now becomes the height */
  width: 100%; /* Cards will stretch to fill the row */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 8px; /* Rounded corners */
}

/* Rounded circle for percentage indicator */
.percentage-circle {
  width: 70px;
  height: 70px;
  border: 5px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}

/* Specific colors for each circle */
.percentage-circle.overdue {
  border-color: #f05050;
  color: #f05050;
}

.percentage-circle.in-progress {
  border-color: #ffbd4a;
  color: #ffbd4a;
}

.percentage-circle.completed {
  border-color: #35e0b8;
  color: #35e0b8;
}

/* Text styles for cards */
.card-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.card-count {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.card-subtitle {
  font-size: 0.9rem;
  color: #888;
}


.custom-popover .popover-header {
  padding-top: 1px; /* Adjust top padding */
  padding-bottom: 1px; /* Adjust bottom padding */
}

.custom-popover .popover-body {
  padding-top: 1px; /* Adjust top padding */
  padding-bottom: 1px; /* Adjust bottom padding */
}


