/*********************************/
/*         Contact us            */
/*===============================*/

.error {
    margin: 8px 0px;
    display: none;
    color: $motos-danger;
}
  
#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message,
#success_page {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 5px;
}

.error_message {
    background-color: rgba($motos-danger, 0.1) !important;
    border: 2px solid rgba($motos-danger, 0.1) !important;
    color: $motos-danger !important;
    font-size: 14px;
}

.contact-loader {
    display: none;
}
  
#success_page {
    background-color: rgba($motos-white, 0.5) !important;
    border: 2px solid rgba($motos-white, 0.5) !important;
    color: $motos-primary !important;
    p {
        margin-bottom: 0 !important;
    }
}
