.col-fixed-90 {
    max-width: 250px;
    margin-top: 1rem;
}

.col-fixed-120 {
    flex: 0 0 120px;
    max-width: 120px;
}

.text-custom{
    color: #8492a6;
    font-size: 15px !important;
    margin-top: 2px;
}

.overdo{
    background-color: cornflowerblue;
    margin-top: 2rem;
}

// Default mobile-first styles for the Select column
.select-column {
    margin: 10px 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .select-column {
        margin: 15px 0; 
        padding-right: 5px; 
        width: 40%; 
    }

    .select-column .react-select__control {
        font-size: 1.1rem; 
        padding: 12px; 
    }

    .select-column .react-select__menu {
        font-size: 1rem; 
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .dropdown-column {
        margin: 15px 0; 
        padding-right: 5px; 
        width: 25%; 
    }

    .dropdown-column .react-select__control {
        font-size: 1.1rem; 
        padding: 12px; 
    }

    .dropdown-column .react-select__menu {
        font-size: 1rem; 
    }
}
@media (min-width: 1025px) {
.contactCheck {
    margin-right: -40px;
}
}
/* Default desktop styles */
@media (min-width: 1025px) {
  .modal-dialog {
    width: 800px; 
    max-width: 90%; 
    height: 600px; 
    max-height: 90%; 
  }

  .modal-content {
    height: 100%;
    overflow: hidden; 
  }

  .modal-body {
    height: calc(100% - 66px - 66px); 
  }

  .modal-header,
  .modal-footer {
    height: 84px;
    justify-content: center;
    align-items: center;
  }
  .onboarding-button{
    width: 100px;
  }
}


/* Mobile styles */
@media (max-width: 768px) {
.onboarding-button{
  margin-left: 60px;
  margin-top: 20px;
}
.onboarding-heading{
  width: 100px;
}
.onboarding-checkbox{
  margin-left: 20px;
}
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
 
}
