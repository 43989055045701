@media (max-width: 768px) {
    .text-end {
      text-align: center !important;
    }
  
    .align-items-center {
      justify-content: center;
    }
  
    .react-select-container {
      text-align: center;
    }
  
    .process-message {
      font-size: 14px !important;
      text-align: center;       
      margin: 0 auto;           
      display: block;          
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 1024px) {
    
    .col-fixed-90 {
      width: 120px; 
      text-align: left; 
    }
  
    .dropdown-column,
    .ml-3 {
      margin-left: 0; 
    }
  
    .form-select,
    .form-control {
      width: 90%; 
      margin-left: 20px; 
    }
  
    .row {
      justify-content: flex-start; 
    }
  }
    