//
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

// authentication pages background
body.authentication-bg {
    background-image: $auth-img-bg;
    background-size: cover;
    background-position: center;
}

// Logout page
.logout-icon {
    width: 140px;
}

.auth-logo {
    .logo-light {
        display: $logo-auth-light-display !important;
    }
    .logo-dark {
        display: $logo-auth-dark-display !important;
    }
}

// dark mode
body[data-layout-color="dark"] {
    .auth-logo {
        .logo-light {
            display: $logo-auth-dark-display !important;
        }
        .logo-dark {
            display: $logo-auth-light-display !important;
        }
    }
    &.authentication-bg {
        background-image: $auth-img-bg-dark;
    }
}

/* Styles for larger screens */
.login-success-container {
    text-align: center;
  }
  .login-success-container h3 {
    color: #343a40;
    margin-bottom: 10px;
  }
  .login-success-container p {
    font-size: 15px;
    color: #8492a6;
    margin-bottom: 50px;
  }
  .submit-button {
    background-color: #8cb369;
    color: white;
    border: none;
    width: 20%;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  .submit-button:hover {
    background-color: #78a054;
  }
  
  /* Responsive styles for mobile screens */
  @media (max-width: 768px) {
    .login-success-container p {
      font-size: 14px;
      margin-bottom: 30px;
    }
    .submit-button {
      width: 60%; /* Adjust width to be more suitable for small screens */
      padding: 10px; /* Increase padding for better touch accessibility */
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .login-success-container p {
      font-size: 13px;
    }
    .submit-button {
      width: 80%; /* Make the button full-width for mobile screens */
      font-size: 14px;
    }
  }
  
  .login-success-container {
    text-align: center;
    padding: 20px;
  }
  
  .otp-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }
  
  .otp-box {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s, box-shadow 0.3s;
  
    &:focus {
      outline: none;
      border-color: #8cb369;
      box-shadow: 0 0 5px rgba(120, 248, 120, 0.5);
    }
  }
  
  .timer-container {
    margin: 10px 0;
    font-size: 16px;
    color: #8492a6;
   
    .resend-button {
      background: none;
      border: none;
      color: #8cb369;
      cursor: pointer;
      text-decoration: underline;
      margin-bottom: 10px;
  
      &:hover {
        color: #78a054;
      }
    }
  }
  
  .verification-button {
    padding: 10px 20px;
    background-color: #8cb369;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  
    &:disabled {
      background-color: #8cb369;
      cursor: not-allowed;
    }
  
    &:hover {
      background-color: #78a054;
    }
  }
