/*********************************/
/*         Blog                  */
/*===============================*/

.blog {
    @each $name, $value in $motos-colors {
        &.blog-#{$name} {
            .title,
            .link {
                &:hover {
                    color: $value !important;
                }
            }
        }
    }
}

.blog {
    .blog-image {
        img {
            transition: all 0.5s ease;
        }
    }
    &:hover {
        .blog-image {
            img {
                transform: scale(1.1);
            }
        }
    }
}
